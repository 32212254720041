import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Img from "gatsby-image"
import LinkedInLogo from "../images/svg/linkedin-logo.svg"

const About = ({ data }) => (
  <Layout>
    <SEO title="About" />

    <Hero
      title="Hello,"
      subtitle="I am Steven. The thriving force behind the Codestack scenes."
    />

    <section className="section about">
      <div className="container">
        <div className="columns">
          <div className="column is-2">
            <figure className="image is-128x128">
              <Img
                fixed={data.profileImage.childImageSharp.fixed}
                className="is-rounded"
                placeholderClassName="is-rounded"
              />
            </figure>
          </div>
          <div className="column">
            <div className="container mb-3">
              <h2 className="accent title is-4">Short introduction</h2>
              <p>
                I am a passionate senior fullstack web developer with over 10
                years of experience with a wide range of clients in diverse
                industries. Due to my expertise in both front- and backend
                technologies, I will bring great value to any team or project.
                Aside from the technical know-how, I possess a healthy dose of
                social skills. I lead by example, use clear communication and
                never shy away from respectfully questioning the status quo.
              </p>
            </div>

            <div className="container mb-3">
              <h2 className="accent title is-4">Quick run on the resume</h2>
              <p>
                I'm constantly adding new tools and techniques to my skill set.
                Here are some key characteristics, based on my latest
                assignments:
              </p>
              <div className="content">
                <ul>
                  <li>C# / .NET Core</li>
                  <li>
                    JavaScript (ES6, TypeScript, Node.js, Electron, Angular)
                  </li>
                  <li>Cloud computing services (GCP, Azure)</li>
                  <li>Containerization platforms (Docker, Kubernetes)</li>
                  <li>Architecture (Microservices, Event-driven)</li>
                  <li>Databases (MongoDB, SQL Server, EF)</li>
                  <li>High profile clients (VeePee, Flemish Government)</li>
                </ul>
              </div>
              <p>
                For a complete overview of my carreer path so far, head over to
                my{" "}
                <a
                  href="https://www.linkedin.com/in/roelandsteven/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin-logo"
                >
                  <span className="linkedin-logo-text">Linked</span>
                  <LinkedInLogo className="linkedin-logo-icon" />
                </a>{" "}
                profile.
              </p>
            </div>

            <div className="container mb-3">
              <h2 className="accent title is-4">Availability</h2>
              <p>Available for short- and long-term assignments</p>
              <p>
                I provide my services both remote and on-site. Currently located
                in Berlin.
              </p>
            </div>

            <div className="container">
              <h2 className="accent title is-4">Testimonials</h2>
            </div>

            <div className="container">
              <div className="columns">
                <div className="column is-6">
                  <div className="box">
                    <article className="media">
                      <div className="media-left">
                        <figure className="image is-64x64">
                          <Img
                            fixed={data.prijckAvatar.childImageSharp.fixed}
                            className="is-rounded"
                            placeholderClassName="is-rounded"
                            alt="Frederik Prijck"
                          />
                        </figure>
                      </div>
                      <div className="media-content">
                        <div className="content">
                          <p>
                            <strong>
                              <a
                                className="testimonial-link"
                                href="https://www.linkedin.com/in/frederikprijck/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Frederik Prijck
                              </a>
                            </strong>{" "}
                            <small>(former colleague)</small>
                            <br />I have had the luck to work on the same
                            project as Steven. From the very first moment it was
                            obvious Steven has a pretty broad knowledge of .NET
                            and general web development. His strong focus on
                            quality and innovation helps the team move forward
                            without giving up the customer satisfaction. Apart
                            from his technical knowledge, his social skills make
                            him a great asset to every team.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="box">
                    <article className="media">
                      <div className="media-left">
                        <figure className="image is-64x64">
                          <Img
                            fixed={data.bosAvatar.childImageSharp.fixed}
                            className="is-rounded"
                            placeholderClassName="is-rounded"
                            alt="Luc Bos"
                          />
                        </figure>
                      </div>
                      <div className="media-content">
                        <div className="content">
                          <p>
                            <strong>
                              <a
                                className="testimonial-link"
                                href="https://www.linkedin.com/in/luc-bos-9b21b767/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Luc Bos
                              </a>
                            </strong>{" "}
                            <small>(former colleague)</small>
                            <br />
                            Working together with Steven has been a pleasure.
                            Steven is a real team player and very communicative.
                            His work methodology is thorough and well thought
                            out. He is a quick learner and does not shy away
                            from difficult or complex tasks. Steven is a person
                            you can have a laugh with and rely on when hard work
                            needs to be done. I'll gladly work together with
                            Steven in the future when the opportunity presents
                            itself.
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default About

export const query = graphql`
  query {
    profileImage: file(relativePath: { eq: "profile.png" }) {
      childImageSharp {
        fixed(width: 128, height: 128, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    prijckAvatar: file(
      relativePath: { eq: "about/frederik-prijck-v2-avatar.png" }
    ) {
      childImageSharp {
        fixed(width: 64, height: 64, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bosAvatar: file(relativePath: { eq: "about/luc-bos-avatar.png" }) {
      childImageSharp {
        fixed(width: 64, height: 64, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
